@import 'two-app-ui/build/esm/src/scss/theme/_variables';

#jobs_page {
  height: 100%;
  overflow: hidden;
  .jobs-content {
    height: 100%;
    .jobs-search {
      min-width: 100%;
    }
  }
  .jobs-scroll-panel {
    height: calc(100% - 1rem - 2px - 1.4rem - 1rem);
    min-width: 100%;
    .p-scrollpanel-bar-y {
      width: 0;
    }
  }
}

#days-accordion {
  .p-accordion-tab {
    margin-bottom: 1rem;
    .p-accordion-header {
      .p-accordion-header-link {
        background-color: $shade600 !important;
        color: $shade000 !important;
        text-decoration: none;
        padding: 0.75rem;
      }
    }
    .p-accordion-content {
      padding: 0 0.75rem;
      background: $shade100;
    }
  }
}

