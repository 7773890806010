@import "../../../../node_modules/two-app-ui/build/esm/src/scss/theme/variables";

#dcm_page {
  height: 100%;
  overflow: hidden;
  .dcm-content {
    height: 100%;
    .dcm-scroll-panel {
      height: calc(100% - 4.3rem - 2px);
      min-width: 100%;
      .p-scrollpanel-bar-y {
        width: 0;
      }
    }
  }
  .summary-panel {
    .p-panel-header {
      background-color: $shade600 !important;
      color: $shade000;
      padding: 1rem;

    }
    .p-panel-content {
      padding: 8px;
    }
  }
  .dcm-bottom-tab-menu {
    //max-width: 768px;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    color: #3B82F6;
  }

  .p-tabmenu .p-tabmenu-nav .p-menuitem-link {
    background: $shade300;
    border: 2px;
    border-radius: 0;
    margin-left: 1px;
  }

  p-tabmenu-ink-bar {
    color: #3B82F6;
  }

  .dcm-bottom-tab-menu-item{
    height: 100%;
  }
}
