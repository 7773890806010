@import 'two-app-ui/build/esm/src/scss/theme/_variables';

#job_detail_page_container{
  //background-color: white;
  height: 100%;
}

#job_detail_page {
  .job-detail-divider::before {
    border-top-color: $shade400;
  }
  .p-scrollpanel{
    height: calc(100% - 1rem - 2px - 1.4rem - 1rem);
    min-width: 100%;
    .p-scrollpanel-bar-y {
      width: 0;
    }
  }
  .job-link{
    color: #3b82f6;
  }
}
